import React from 'react';
import { makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    flexBasis: 300,
  },
  menu: {
    width: 200,
  }
}));


export default function AdvancedSearch( obj) {

  const classes = useStyles();
  const skills    = obj.store.serverSkills.skills;
  const locations = obj.store.serverLocations.locations;
  
  const [values, setValues] = React.useState({
          location :   '*',
          skill    :   '*',
  });
  
  const handleChange = prop => event => {
      setValues({ ...values, [prop]: event.target.value });
  };

  const handleCancel = () => {
     setValues({ ...values, location: '*', skill:'*'});
     obj.actions.PWA_AdvancedSearch_Toggle();
  };

  const handleSearch = () => {
    obj.actions.PWA_Get_Candidates( values.skill, values.location);
    setValues({ ...values, location: '*', skill: '*'});
    obj.actions.PWA_AdvancedSearch_Toggle();

  };

  return (
    <React.Fragment>
      <Dialog  open={obj.store.advancedSearch.show} onClose={handleCancel} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Advanced Search</DialogTitle>
        <DialogContent>
          <DialogContentText>Select Skill, Location or both</DialogContentText>
        
          <div className={classes.root}>

           <TextField
            className={classes.textField}
            select
            autoFocus
            margin="dense"
            id="skill"
            label="Skill"
            value={values.skill}
            onChange={handleChange('skill')}
            fullWidth
          >

           { 
                skills !== undefined ?
                   skills.map( (name, key) => (
                     <MenuItem key={ key} value={ name}>{ name }</MenuItem>)
                   )
                : null
           }

          </TextField>
          
          <TextField
            className={classes.textField}
            id="Location"
            select
            label="Location"
            margin="dense"
            value={values.location}
            onChange={handleChange('location')}
            fullWidth
          >
        
        { 
                locations !== undefined ?
                   locations.map( (name, key) => (
                     <MenuItem key={ key} value={ name}>{ name }</MenuItem>)
                   )
                : null
           }

          </TextField>
           </div>

        </DialogContent>

        <DialogActions>
          <Button onClick={ handleSearch }  color="primary">Search</Button>
          <Button onClick={ handleCancel }  color="secondary">Cancel</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
