import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import SkillsList from './SkillsList'
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right:  theme.spacing(1),
    top:    theme.spacing(1),
    color:  theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


export default function DetailModal( obj ) {

  if  ( obj.store.showDetail.show === false )
        return null;
  
  const candidate = obj.store.showDetail.candidate;

  return (
    <div>
      <Dialog 
             id = "dl_details_text"
             TransitionComponent={Transition}
             keepMounted
             onClose = { () => obj.actions.PWA_Show_Detail_Close() }
             aria-labelledby="customized-dialog-title"
             open ={ obj.store.showDetail.show }>
        <DialogTitle id="customized-dialog-title" onClose={obj.close}>
           Position Details:
        </DialogTitle>
        <DialogContent dividers>
        <Typography variant="h6" gutterBottom>
            {candidate.Id}
          </Typography>
          <Typography gutterBottom>
            Job Title: {candidate.JobTitle}
          </Typography>
          <Typography gutterBottom>
             Location: {candidate.Location}
          </Typography>
          <Typography gutterBottom>
           <SkillsList {...candidate.Skills}/>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => obj.actions.PWA_Show_Detail_Close() } color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}