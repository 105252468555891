import React                from 'react';
import { makeStyles }       from '@material-ui/core/styles';
import AppBar               from '@material-ui/core/AppBar';
import Toolbar              from '@material-ui/core/Toolbar';
import IconButton           from '@material-ui/core/IconButton';
import Badge                from '@material-ui/core/Badge';
import MenuIcon             from '@material-ui/icons/Menu';
import AccountCircle        from '@material-ui/icons/AccountCircle';
import Divider              from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
    marginBottom:50,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  sectionMobile: {
     display: 'flex',
     [theme.breakpoints.up('md')]: {
       display: 'none',
    },
  },

}));

function handleFabClick( obj ) {
  switch (obj.store.currentView) {
    case "SEARCH_VIEW":
      obj.actions.PWA_Set_View("MAILER_VIEW");
      return ;
    case "MAILER_VIEW":
      obj.actions.PWA_Set_View("SEARCH_VIEW");
      return;
    default:
      return;
  }
} 

export default function mailerHeaderAppBar( obj ) {

  if  ( obj.store.currentView !== "MAILER_VIEW")
     return null;


  const classes                 = useStyles();
  const barColorScheme          = obj.store.currentView === "SEARCH_VIEW" ? "primary" : "secondary"
  const selectedCount           = obj.store.selectedCount;
   
  return (

    <React.Fragment>
      <AppBar color={ barColorScheme } position="fixed">
          <Toolbar>
            <Divider />

            <IconButton
              edge      = "start"
              className = {classes.menuButton}
              color     = "inherit"
              aria-label= "open drawer"
             >
             <MenuIcon />
             </IconButton>
       
              
              <div className={classes.grow} />
       
           <IconButton onClick = { () => handleFabClick(obj) } aria-label="" color="inherit">
              <Badge badgeContent={selectedCount} color="primary">
                 <AccountCircle />
              </Badge>
            </IconButton>
       
        </Toolbar>
      </AppBar>
      <Toolbar />
      </React.Fragment>
  );
}
