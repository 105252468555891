import React from 'react';
import { makeStyles }     from '@material-ui/core/styles';
import List               from '@material-ui/core/List';
import ListItem           from '@material-ui/core/ListItem';
import ListItemText       from '@material-ui/core/ListItemText';
import Grid               from '@material-ui/core/Grid';
import Typography         from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
     margin: theme.spacing(1, 0, 0),
  },
  item: {
    marginTop:     '0px', 
    marginBottom:  '0px', 
    paddingTop:    '0px',
    paddingBottom: '0px',
  }

}));

function generate(obj) {

    const skills  = Object.values(obj);
    const classes = useStyles();

    const _skillsList = skills.map( (item, key ) => 
    <ListItem  key = {key} className={classes.item} >
       <ListItemText className={classes.item} primary={item} />
    </ListItem>
   );

    return _skillsList;
}

export default function SkillsList( obj) {
 
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle2" className={classes.title}>
              Skills:
          </Typography>
            <List dense= {true} >
              { generate(obj) }
            </List>
          </Grid>
      </Grid>
    </div>
  );
}
