import React from 'react';
import { makeStyles }   from '@material-ui/core/styles';
import Typography       from '@material-ui/core/Typography';
import Paper            from '@material-ui/core/Paper';
import Grid             from '@material-ui/core/Grid';


const useStyles = makeStyles(theme => ({
  root: {
     margin: "2em",
     padding: theme.spacing(3,2),
     fontSize: 12,
   // borderStyle: "dashed",
     overflow: "auto",
  //   height: 400,
     paddingTop: 5,
     marginTop: 40
  },
  header: {
    margin: "1em",
 }, tester: {
     display: "flex",
     flexDirection: "column",
 }, testerItemTitle : {
    display: "flex",
    justifyContent: "center",
    fontSize:26,
    color: "#B9802B"
 }, testerItemSubTitle : {
     display: "flex",
     justifyContent: "center",
     fontSize:12,
     fontFamily: 'Open Sans',
     fontWeight:600
 },  adjustfont : {
     fontSize: 11,
     fontFamily: 'Open Sans',
     marginBottom: '5px',
     fontWeight: 500
 },  adjustfontBold : {
     fontSize: 11,
     fontFamily: 'Open Sans',
     marginBottom: '5px',
     fontWeight: 700
 },
 container : {
    alignItems: "end",
 }, lightergold : {
    color: "#dfc049",
    fontWeight: 600
 }, innerPaper :{
    padding: 10,
    border: "1px solid #dfc049",
    backgroundColor: "#FAFAFADB"
 }

}));


export default function Instrucions( obj ) {

  const classes = useStyles();

  if  ( obj.store.serverCandidates.length > 0 )
        return null;
  
  return (
    <React.Fragment>
     
      <Paper className={classes.root}>

        <Grid container className={classes.container}>
           
            <Grid item xs={2}>
                 <img src="./images/logo-180.png" alt="Portman Recruitment" height="60px" width="60px" />
            </Grid>
  
            <Grid className={classes.tester} item xs={10}>

              <Typography className={classes.testerItemTitle} variant="subtitle2">
                  What's Available
              </Typography>
         
              <Typography className={classes.testerItemSubTitle} variant="subtitle2">
                  <span className={classes.lightergold}>Simple&nbsp;</span>
                  Live Job Search
                  <span className={classes.lightergold}>&nbsp;PWA</span>
              </Typography>

            </Grid >
        
        </Grid>

         <Paper className={classes.innerPaper} id="innerpaper">

          <Typography gutterBottom variant="subtitle2">
           </Typography>
            
           <Typography className={classes.adjustfont} gutterBottom variant="subtitle2">
             Please enjoy our FREE to use - Job Search PWA
           </Typography>
      
          <Typography className={classes.adjustfont} gutterBottom variant="subtitle2">
             Jobs are updated every day and the What's Availiable App is fully
             GDPR compliant.
           </Typography>
        
           <Typography className={classes.adjustfontBold} gutterBottom variant="subtitle2">
             <strong>It couldn't be more simple to use</strong>
            </Typography>
        
           <Typography className={classes.adjustfont} gutterBottom variant="subtitle2">
             If you want to view jobs by location, simply type your town or
             location e.g Manchester and we will instantly display live jobs.
           </Typography>

            <Typography className={classes.adjustfont} gutterBottom variant="subtitle2">
             You can view permanent / temp positions by selecting an option
             from the Type menu, or view jobs by Category. Simple. 
            </Typography>
   
            <Typography className={classes.adjustfontBold} gutterBottom variant="subtitle2">
             <strong>Interested in a position ?</strong>
            </Typography>
  
            <Typography className={classes.adjustfont} gutterBottom variant="subtitle2">
             Simply click /tick/press the gold circle on the left of any job, which
             shortlists the position you are interested in. Tick as many as you like.
            </Typography>
      
            <Typography className={classes.adjustfontBold} gutterBottom variant="subtitle2">
             <strong>How to Apply ?</strong>
            </Typography>
  
            <Typography className={classes.adjustfont} gutterBottom variant="subtitle2">
                Once you have one or more ticked. Just click the 'send email' button.
                Provide a few basic details and we will be in touch.
            </Typography>

            <Typography className={classes.adjustfont} variant="subtitle2">
                <strong>*Tip:</strong> Click 'Reset Search Results' to clear all filters
            </Typography>
            </Paper>
      
      </Paper>
    
    </React.Fragment>
  );
}