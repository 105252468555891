import React                from 'react';
import { makeStyles }       from '@material-ui/core/styles';
import AppBar               from '@material-ui/core/AppBar';
import Toolbar              from '@material-ui/core/Toolbar';

//import Badge                from '@material-ui/core/Badge';
//import RotateLeftIcon       from '@material-ui/icons/RotateLeft';
//import ClearIcon            from '@material-ui/icons/Clear';
//import DeleteSweepIcon      from '@material-ui/icons/DeleteSweep';
//import IconButton           from '@material-ui/core/IconButton';
import ReactAutoSuggest       from './ReactAutoSuggest';
import ReactComboFilter       from './ReactComboFilter';
import Grid                   from '@material-ui/core/Grid';



const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  mTop5 :{
    marginTop : 5
  },
  mRight10 : {
    marginRight : 10
  },
  locationFilter : {
    marginBottom : 5
  },
  filters: {
    display        : "flex",
    flexDirection  : "row",
  },
  grow: {
    flexGrow: 1,
    marginBottom: 50,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
     display        : "flex",
     flexDirection  : "row",

  }, reset: {
     flex: "0 0 auto",
  }, search: {
     flex: "1 1 auto",
  }, appBar:{
     backgroundImage: "linear-gradient(-4deg, #b9812c 0%, #e3c74d 100%)",
     color: "#fff",
     minHeight: 78
  },  
}));

/*
function handleFabClick( obj ) {
  switch (obj.store.currentView) {
    case "SEARCH_VIEW":
      obj.actions.PWA_Set_View("MAILER_VIEW");
      return ;
    case "MAILER_VIEW":
      obj.actions.PWA_Set_View("SEARCH_VIEW");
      return;
    default:
      return;
  }
} 
*/

/*
function resetPositionsList(obj) {
    obj.actions.PWA_Reset_Candidates();
//  const input = document.querySelector(".react-autosuggest__input");
//  input.focus();
} 
*/

export default function SearchHeaderAppBar( obj ) {

  if  ( obj.store.currentView !== "SEARCH_VIEW")
        return null;

  const classes                 = useStyles();
  // const barColorScheme          = obj.store.currentView === "SEARCH_VIEW" ? "primary" : "secondary"
  // const selectedCount           = obj.store.selectedCount;
  
  //   <div className={classes.grow} />
 /* <IconButton onClick = { () => handleFabClick(obj) } aria-label="" color="inherit">
    <Badge badgeContent={selectedCount} color="secondary">
      <AccountCircle />
    </Badge>
  </IconButton>
*/

  return (

    <React.Fragment>
    {/* </React.Fragment>  <AppBar color={ barColorScheme } position="fixed"> */}

      <AppBar className={classes.appBar} position="fixed">
          <Toolbar className={classes.mTop5}>

           <div className={classes.root}>
   
                    <Grid  className={classes.locationFilter} item xs={12}>
                         <ReactAutoSuggest placeholder={'Location'}/> 
                    </Grid>
                
                    <div  className={classes.filters}>
                      <Grid className={classes.mRight10} item xs={6}>
                           <ReactComboFilter placeholder={'Category'} /> 
                      </Grid>
                
                      <Grid item xs={6}>
                          <ReactComboFilter placeholder={'Employment'} /> 
                      </Grid>

                    </div>
       
          </div>
          </Toolbar>
      </AppBar>
      
      <Toolbar />
      </React.Fragment>
  );
}
