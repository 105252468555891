import React from 'react';
import { withStyles }   from '@material-ui/core/styles';
import Button           from '@material-ui/core/Button';
import Dialog           from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography       from '@material-ui/core/Typography';
import Slide            from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import MailerInput      from './MailerInput';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(1),
  },
}))(MuiDialogContent);


const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


export default function MailerModal( obj ) {

  if  ( obj.store.showMailer.show === false )
        return null;


  const [values, setValues] = React.useState({
          name           :  '',
          phone          :  '',
          email          :  '',
          comment        :  '',
  });

  const mailerUpdate = prop => event => { 
     setValues({ ...values, [prop]: event.target.value });
  }
   
  function isMailerValid () {
  
    let result = emailIsValid (values.email ) &&
                 inputIsValid (values.email ) &&
                 inputIsValid (values.name  ) &&
                 inputIsValid (values.phone );

    return ( !result );
  } 

  const handleSendClick  = () => { 

    var selectedCandidates = obj.store.serverCandidates.filter(function ( candidate ) {
        return  candidate.selected === true;
    });

    var mailerObj = {};
    mailerObj.requesterEmail   = values.email;
    mailerObj.requesterName    = values.name;
    mailerObj.requesterPhone   = values.phone;
    mailerObj.requesterComment = values.comment;
    mailerObj.candidates       = selectedCandidates;
    obj.actions.PWA_Post_Email( mailerObj ) 

  }
  
  const handleToogle  = () => { 
    obj.actions.PWA_Post_Email_Toogle()
  }
  
  function emailIsValid(str) {
     return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test( str )
  }
  
  function inputIsValid(str) {
        return ( str.length > 5 ? true : false);
  }


  return (
    <div>

      <Dialog
             fullWidth = {true}
             TransitionComponent={Transition}
             keepMounted
             onClose = { () => obj.actions.PWA_Post_Email_Toogle() }
             aria-labelledby="customized-dialog-title"
             open ={ obj.store.showMailer.show }
             >

        <DialogContent dividers>

        <Typography variant="body1" align="center" gutterBottom>
                Jobs selected :  {obj.store.selectedCount} 
        </Typography>
        
        <MailerInput  _mailerValues = { values        }
                      _mailerChange = { mailerUpdate  } />


        </DialogContent>

        <div class="email-footer" id="tester">
         
         { obj.store.showMailerProgress ? <CircularProgress size="30px"/> : null}

         <DialogActions>
            <Button onClick=   { handleToogle}    color="secondary">Cancel</Button>
            <Button onClick=   { handleSendClick} color="primary" disabled = { isMailerValid() }>Send</Button>
         </DialogActions>
        </div>

      </Dialog>
    </div>
  );
}