import axios                       from        'axios';

//import { readRemoteFile }          from        'react-papaparse'

 //     const  baseURL = "../server";
 //     const  baseURL = "http://localhost/whatsavailable_v2";
        const  baseURL = "https://portmanrecruitment.com/whatsavailable_v2"
 //     const  baseURL = "https://whatsavailable.co.uk/server";

       const  xdebug  = "";
 //    const  xdebug         = "?XDEBUG_SESSION_START=netbeans-xdebug";

  
export const PWA_Get_Jobs  = () => {
 
    let sURL          = baseURL +  "/index.php" + xdebug;
//  let sURL          = baseURL +  "/pwa_jobs.php" + xdebug;
    const encodedURL  = encodeURI( sURL );

    return dispatch => {
        
       dispatch({ type:'PWA_GET_JOBS_STARTED'});
       axios.get( encodedURL )
        .then( ( response ) => {
                dispatch({ type:'PWA_GET_JOBS_SUCCESS',  payload: response});
        }).catch(( error ) => {
                dispatch({ type:'PWA_GET_JOBS_FAIL',     payload: error.message});
        });

      //  readRemoteFile( encodedURL,{
      //      header: true,
      //      complete: ( response) => {  
      //          dispatch( { type:'PWA_GET_JOBS_SUCCESS', payload: response});
      //       }
      //  });
    }
}

export const PWA_Post_Email   = ( mailerObj ) => {

    let   sURL        = baseURL +  "/pwa_mailer.php" + xdebug;
    const encodedURL  = encodeURI( sURL );
   
    var mailerJson    = JSON.stringify(mailerObj);
   
    return dispatch => {
        dispatch({ type:'PWA_POST_EMAIL_STARTED'});
        axios.post( encodedURL, mailerJson )
        .then( ( response ) => {
                   dispatch({ type:'PWA_POST_EMAIL_FINISHED',  payload: response.data});
       }).catch(( error ) => {
                   dispatch({ type:'PWA_POST_EMAIL_FAIL',      payload: error});
       });
   }
}
export const PWA_Post_Email_Toogle = () => {
    return dispatch => {
        dispatch( { type: 'PWA_POST_EMAIL_TOOGLE'} );
    }
}

export const PWA_Get_Status  = () => {
    
    let sURL          = baseURL +  "/pwa_get_status.php" //+ xdebug;
    const encodedURL  = encodeURI( sURL );

    return dispatch => {
        dispatch({ type:'PWA_GET_STATUS_STARTED'});
         axios.get( encodedURL )
         .then( ( response ) => {
                   dispatch({ type:'PWA_GET_STATUS_SUCCESS', payload: response.data});
        }).catch(( error ) => {
                    dispatch({ type:'PWA_GET_STATUS_FAIL',    payload: error.message});
        });
    }
}

export const PWA_Get_Candidates   = ( filterField ='*', filterValue='*' ) => {
    
    var data = {  "filterField" : filterField,  "filterValue" : filterValue };
    return dispatch => {
          dispatch({ type:'PWA_GET_CANDIDATES_STARTED' });
          dispatch({ type:'PWA_GET_CANDIDATES_SUCCESS', payload: data});
    }
}
export const PWA_Reset_Candidates = ( ) => {
    return dispatch => {
        dispatch( { type: 'PWA_RESET_CANDIDATES' });
    }
}
export const PWA_Candidate_Clicked  = ( candidate ) => {
    return dispatch => {
        dispatch( {type:'PWA_CANDIDATE_CLICKED', payload: candidate} );
    }
}


export const PWA_Set_View  = ( view ) => {
    return dispatch => {
        dispatch( { type: 'PWA_SET_VIEW',    payload: view});
    }
}
export const PWA_AdvancedSearch_Toggle = () => {
    return dispatch => {
        dispatch( { type: 'PWA_ADVANCEDSEARCH_TOGGLE'});
    }
}
export const PWA_AdvancedSearch_Process = ( query ) => {
    return dispatch => {
        dispatch( { type: 'PWA_ADVANCEDSEARCH_PROCESS', payload: query });
    }
}
export const PWA_Show_Detail_Open = ( candidate ) => {
    return dispatch => {
        dispatch( { type: 'PWA_SHOW_DETAIL_OPEN',    payload: candidate});
    }
}
export const PWA_Show_Detail_Close = ( ) => {
    return dispatch => {
        dispatch( { type: 'PWA_SHOW_DETAIL_CLOSE' });
    }
}

export const PWA_Show_Permanent_Details_Open = ( details ) => {
    return dispatch => {
        dispatch( { type: 'PWA_SHOW_PERMANENT_DETAILS_OPEN',    payload: details});
    }
}
export const PWA_Show_Permanent_Details_Close = ( ) => {
    return dispatch => {
        dispatch( { type: 'PWA_SHOW_PERMANENT_DETAILS_CLOSE' });
    }
}

