import React                        from 'react';
import { makeStyles }               from '@material-ui/core/styles';
import List                         from '@material-ui/core/List';
import ListItem                     from '@material-ui/core/ListItem';
import ListItemText                 from '@material-ui/core/ListItemText';
import Typography                   from '@material-ui/core/Typography';
import Fab                          from '@material-ui/core/Fab';
import CheckIcon                    from '@material-ui/icons/Check';
import Button                       from '@material-ui/core/Button';
//import { Rowing }                   from '@material-ui/icons';

const marginRight = {
  marginRight : '20px'
};

const useStyles = makeStyles(theme => ({
 
   root: {
    width: '100%',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    padding: 0,
    margin: 0,
    display: 'flex',
    float: 'left',
  },inline: {
    display: 'inline',
  }, termItem : {
     display : 'flex',
    flexDirection:"row"
  }, employment_term : {
    display        :  'flex',
    alignItems     :  'baseline',
    justifyContent :  'space-between',
  }, 
  block: {
    display: 'block',
  },
  fab: {
    margin: theme.spacing(2),
  },
  list:{
    width: '100%',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    padding: 1,
    margin: '24px 0px 56px 0px',
    display        : "flex",
    flexDirection  : "column",
    alignItems     : "center",
    justifyContent : "center",
    border: 0,
    borderRadius: 3,
 //   color: 'white',
  },
  listItem:{
    display        : "flex",
    flexDirection  : "row",
  },
  listitemselected :{
    display          : "flex",
    flexDirection    : "row",
    border           : "1px solid", 
    backgroundImage  : "linear-gradient(-4deg, #b9812c 0%, #e3c74d 100%)",
 //   backgroundColor  : "lightblue",
    borderRadius     : "10px",
    boxShadow        : "0 0 10px  rgba(0,0,0,0.6)"
  },
  listItemText:{
    flex: 2
  },
  fabselected:{
    backgroundImage  : "linear-gradient(-4deg, #b9812c 0%, #e3c74d 100%)",
  }  
}));

const IsCandidateSelectedIcon = (props) => {
   return ( props.selected ? <CheckIcon /> : null);
};


function CandidatesList( obj ) {

  if ( obj.store.serverCandidates.length === 0 )
       return null;

  const classes = useStyles();

  function handleCandidateClick(event) { 
      let _candidate  = JSON.parse( event.currentTarget.getAttribute("data-item"));
      obj.actions.PWA_Candidate_Clicked  ( _candidate );
  }

  function isPermanentPosition( item ) {
     const isPermanent = item.employment;
     if (isPermanent === "permanent")
         return <Button onClick=   {  () => handlePermanentDetailsClick( item.description ) } variant="contained" color="primary">Details</Button>;
     else 
        return null;    
  }

  const showTeam = (item) => {
    return ( item.employment === "temporary" ? `Team: ${ item.team }` : null );
  }

  const showCategory = (item) => {
     return ( item.employment === "temporary" ? `Category: ${ item.category}` : null );
  }

  const showPay    = (item) => {
    let pay     = removeCurrencySymbol( item.pay_rate );
    let result  =  `Pay rate : £ ${pay}`;
    return result;
  }

  const capitalize = (s) => {
     if (typeof s !== 'string') return ''
     return s.charAt(0).toUpperCase() + s.slice(1)
  }

  const removeCurrencySymbol = (s) => {
    if (typeof s !== 'string') return ''
    let desired = s.replace("£", "");
    return desired;
  }

  
  function handlePermanentDetailsClick( details ) {
      obj.actions.PWA_Show_Permanent_Details_Open  ( details );
  }

  /*
    function handleDetailClick(event) {
     let _candidate            = JSON.parse( event.currentTarget.getAttribute("data-item"));
     obj.actions.PWA_Show_Detail_Open  ( _candidate );
  }
  */  
   var candidates = obj.store.serverCandidates.filter(function ( candidate ) {
    //   return  candidate.selected === onlySelectedCandidates;
         return candidate;
   });

  const _candidatesList = candidates.map( (item, key ) => 
     <ListItem  className   = { item.selected ?  'selected_listitem' : 'unselected_listitem' }
                key         = {key}
                alignItems  = "center"
                divider>
    
        <Fab  style={marginRight}
              size        = "small"
              color       = {item.selected ? "primary" : "secondary"}
              aria-label  = "add"
              variant     = "round"
              onClick     = { handleCandidateClick }
              data-item   = { JSON.stringify( { 'candidate': item, 'key': key } ) }>
             <IsCandidateSelectedIcon selected = {item.selected} /> 
        </Fab>
    
 
        <ListItemText className= {classes.listItemText}
         
           primary={

               <React.Fragment>
                <div className = { `${ item.employment === "temporary" ? "title_listItem" : ""}` } > 
                  <Typography
                     component ="div"
                     variant   ="subtitle2"
                  >
                  { item.title }
                  </Typography> 
           
                  <Typography
                     component ="div"
                     variant   ="subtitle2"
                  >
                  { showPay(item) }
                  </Typography> 
                  </div>
              </React.Fragment>
            }

            secondary={

              <React.Fragment>
            
                <Typography
                   component='span'
                   variant   ="subtitle2"
                   className ={classes.block}
                >
                Location: {item.location}
                </Typography>

                <Typography
                  component ='span'
                  variant   ="subtitle2"
                  className ={classes.block}
                >
                { showTeam(item)  }
                </Typography>

                <Typography
                  component='span'
                  variant   ="subtitle2"
                  className ={classes.block}
                >

                { showCategory(item) }
                </Typography>

                <Typography
                   component='span'
                   variant   ="subtitle2"
                   className ={classes.employment_term}
                >
                  Term: { capitalize( item.employment) }
                  {  isPermanentPosition(item) }

                </Typography>
 
           
                </React.Fragment>


            }


        />

  {/*  
      <Fab 
           size        = "small"
           aria-label  = "add"
           variant     = "round"
           onClick     = { handleDetailClick }
           data-item   = { JSON.stringify( { 'candidate': item, 'key': key }) }>
          <PermIdentityOutlinedIcon />
     </Fab>
   */}

    </ListItem>
  );


  return (
    
      <List className= {classes.list}>
           {_candidatesList}
       </List>
  );

}

export default CandidatesList;

//<Box display="flex"  alignItems="center" justifyContent="center" flexDirection="column" p={1} bgcolor="background.paper">
//{_candidatesList}
//</Box>    
