import { toast }    from 'react-toastify';
//var toastId = null;

const initialState = {
  
      currentView           :  "SEARCH_VIEW",
      serverSkills          :  [],
   
      serverLocations       :  [],
      serverTeams           :  [],
      serverEmploymentTypes :  [],
      serverCategories      :  [],
      serverJobs            :  [],
      
      serverCandidates      :  [],
      selectedCount         :  0,
      showDetail            :  { show:false,  candidate:null},
      advancedSearch        :  { show:false,  location :null, skill:null },
      showMailer            :  { show:false},
      showPermanentDetails  :  { show:false, details : ""},
      showMailerProgress    :  false 
  }

  export const AppReducer = (state=initialState, action) => {

    switch(action.type) {

      case 'PWA_FILTER_JOBS' : {
        var  newCandidates     = [];
        var  jobs              = state.serverJobs.slice(0);
        jobs.filter( ( job ) => { 
          if ( job[ action.payload.filterField] === action.payload.filterValue) {
             newCandidates.push( job);
           }
           return true;
        });

         return { ...state, serverCandidates : newCandidates };
      }

      case 'PWA_GET_JOBS_STARTED' :{
        return { ...state };
      }

      case 'PWA_GET_JOBS_SUCCESS' :{
      
        try{
                
            var _jobsLocations        = [];
            var _jobsTeams            = [];
            var _jobsEmploymentTypes  = [];
            var _jobsCategories       = [];
                   
            var _jobsData            =  action.payload.data;
            var _lowerCaseJobsData   = _jobsData.data.map( (element ) => {
      
              
               element = toLowerCaseKeys    ( element );  // set this objects keys to lowercase, as they are used as locawercase
               element = removeQuestionMark ( element );

               if ( _jobsLocations.indexOf( element.location) === -1)
                    _jobsLocations.push( element.location );
       
               if ( _jobsTeams.indexOf( element.team) === -1)
                    _jobsTeams.push( element.team );

               if ( _jobsEmploymentTypes.indexOf( element.employment) === -1)
                    _jobsEmploymentTypes.push( element.employment );

               if(element.category.length > 0 ) {     
                  if ( _jobsCategories.indexOf( element.category) === -1)
                       _jobsCategories.push( element.category );
               }

               return ( element );     
            });
 
          } catch (err) {
              toast.error( "PWA_Get_Jobs()\n" + err.message, { position: toast.POSITION.TOP_LEFT, autoClose: 6000} );
          } finally {

            return { 
                    ...state,
                     serverLocations       : _jobsLocations,
                     serverTeams           : _jobsTeams,
                     serverEmploymentTypes : _jobsEmploymentTypes,
                     serverCategories      : _jobsCategories,
                     serverJobs            : _lowerCaseJobsData
                    };

          }

      }
      case 'PWA_GET_JOBS_FAIL' : {
        toast.error( "PWA_Get_Jobs()\n" + action.payload, { position: toast.POSITION.TOP_LEFT, autoClose: 6000} );
        return { ...state };
      }
            
      case 'PWA_POST_EMAIL_STARTED' : {
        return { ...state,  showMailerProgress: true};
      }

      case 'PWA_POST_EMAIL_FINISHED' : {
         let response = action.payload;
         if ( response.exception === true){  //we have an exception
            toast.error ( response.message, { position: toast.POSITION.TOP_CENTER, autoClose: 6000 } );
            return { ...state,  showMailerProgress: false  };
        }else {
            return { ...state,  showMailerProgress: false,  serverCandidates: [], showMailer : false, selectedCount : 0 };
        }
      }
  
      case 'PWA_POST_EMAIL_FAIL' : {
        let response = action.payload;
        toast.error ( response.message, {  position: toast.POSITION.TOP_CENTER, autoClose: 3000 } );
        return { ...state, showMailerProgress: false  };
      }

      case 'PWA_POST_EMAIL_TOOGLE' : {
         var newState2 = { show: !state.showMailer.show }
         return { ...state, showMailer : newState2 };
      }




      case 'PWA_GET_STATUS_STARTED' : {
        return { ...state };
      }
      case 'PWA_GET_STATUS_SUCCESS' : {
        return { ...state }
      }
      case 'PWA_GET_STATUS_FAIL' : {
        toast.error(  "getStatus() : " + action.payload, { autoClose: 6000 } );
        return { ...state };
      }
   
      case 'PWA_GET_TEAMS_STARTED' : {
        return { ...state};
      }
      case 'PWA_GET_TEAMS_SUCCESS' : {
        return { ...state, serverTeams : action.payload };
      }
      case 'PWA_GET_TEAMS_FAIL' : {
        toast.error(  "getTeams() : " + action.payload, { autoClose: 6000 } );
        return { ...state };
      }
      

      case 'PWA_GET_LOCATIONS_STARTED' : {
        return { ...state};
      }
      case 'PWA_GET_LOCATIONS_SUCCESS' : {
        return { ...state, serverLocations : action.payload };
      }
      case 'PWA_GET_LOCATIONS_FAIL' : {
        toast.error(  "getLocations() : " + action.payload, { autoClose: 6000 } );
        return { ...state };
      }

      case 'PWA_CANDIDATE_CLICKED' : {

        var   candidate              = action.payload.candidate;
        var   newCandidates1         = state.serverCandidates.slice(0);

        // update the candidate and toggle the selected state
        newCandidates1.forEach(function ( _item ) {
          if ( _item.Id === candidate.Id)  
               _item.selected = !_item.selected;
        });

        // get the selected count 
        var filtered = newCandidates1.filter( function( Candidate) {
          if (Candidate.selected && Candidate.selected === true)
              return Candidate;
          else 
              return false;    
        });

        return { ...state, serverCandidates : newCandidates1, selectedCount: filtered.length};
      }
      case 'PWA_GET_CANDIDATES_STARTED' : {
         return { ...state};
      }
      case 'PWA_GET_CANDIDATES_SUCCESS' : {

         var  _newResults    =  filterJobsArray( state.serverJobs, action.payload.filterField,action.payload.filterValue);
         var  _newCandidates =  generateNewCandidatesArray(state.serverCandidates, _newResults);
         return { ...state,  serverCandidates :  _newCandidates };
      }

      case 'PWA_GET_CANDIDATES_FAIL' : {
        toast.error(  "getCandidates() : " + action.payload, { autoClose: 6000 } );
        return { ...state };
      }
      case 'PWA_RESET_CANDIDATES' : {
        return { ...state, selectedCount : 0, serverCandidates : [] };
      }

      case 'PWA_SET_VIEW': {
        return { ...state,  currentView: action.payload }
      }
      
      case 'PWA_SHOW_DETAIL_OPEN': {
        var newShowDetailOpen = { show: true, candidate: action.payload.candidate}
        return { ...state,  showDetail: newShowDetailOpen}
      }

      case 'PWA_SHOW_DETAIL_CLOSE': {
        var newShowDetailClose = { show: false, candidate: null}
        return { ...state,  showDetail: newShowDetailClose}
      }

      case 'PWA_SHOW_PERMANENT_DETAILS_OPEN': {
        var permanentDetailsOpen = { show: true, details: action.payload}
        return { ...state,  showPermanentDetails: permanentDetailsOpen}
      }

      case 'PWA_SHOW_PERMANENT_DETAILS_CLOSE': {
        var permanentDetailsClose = { show: false, details: null}
        return { ...state,  showPermanentDetails: permanentDetailsClose}
      }

      case 'PWA_ADVANCEDSEARCH_TOGGLE': {
        return { ...state,  advancedSearch : {show : !state.advancedSearch.show} };
      }
      
    
      default:
          return { ...state };
    }
  }

  function toLowerCaseKeys(obj) {
    return Object.keys(obj).reduce(function(accum, key) {
      accum[key.toLowerCase()] = obj[key];
      return accum;
    }, {});
  };


  function removeQuestionMark(obj) {

 
    if ( obj.hasOwnProperty('payrate') ){
         let desired = obj.payrate.replace("?", "");
         obj.payrate = desired;
    }

    if ( obj.hasOwnProperty('pay_rate') ){
          let desired = obj.pay_rate.replace("?", "");
          obj.pay_rate = desired;
    }

     return obj;
  };


  var filterJobsArray             = (jobs, filterField, filterValue) => {

      /////////////////////////////////////////   
        // filter the current serverJobs array//
        ////////////////////////////////////////
        var  results         = [];
        results = jobs.filter( ( job ) => { 
            if ( job[filterField].toLowerCase() === filterValue.toLowerCase() ) {
                  job['Id']        = job['id'];
                  job['selected']  = false;
                  return true;
            } else 
                  return false;
        });

        return results;
  };

  var generateNewCandidatesArray = ( currentCandidates, newResults) => {

        var  newCandidates     = [];

        if (currentCandidates.length === 0 )
            newCandidates  = currentCandidates.concat(newResults);
        else {

                newResults.map( (item)  => {
                       let pos = currentCandidates.map( (e) => { return e.Id; }).indexOf(item.Id);
                       if (pos === -1)
                         currentCandidates.unshift(item);
                      return true;   
                 });
                 newCandidates     = currentCandidates;
        }
        return newCandidates;
};
