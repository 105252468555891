import React, { Component }         from 'react'
import { connect }                  from 'react-redux';
import { bindActionCreators }       from 'redux';
import * as AppActions              from '../store/actions/AppActions';


function mapDispatchToProps(dispatch) {
    const actions = Object.assign( {}, AppActions );
    return { actions: bindActionCreators( actions, dispatch)  };
}

function mapStateToProps(store) {
   return { AppReducer : store.AppReducer };
}

class ReactComboFilter extends Component {
  
  constructor(props) {
      super(props);
      this.state                         =  { anchorEl : null };
  }
      
  handleChange = (event) => {
    if ( event === null )
         return;
 
    const filterField = this.props.placeholder.toLowerCase();
    const filterValue = event.target.value;
    this.props.actions.PWA_Get_Candidates(filterField, filterValue);
  };

  generateStyles = () => {
    const customStyles = {
      option: provided => ({
        ...provided,
        color: 'black'
      }),
      control: provided => ({
        ...provided,
        color: 'black'
      }),
      placeholder: provided => ({
            ...provided,
            fontSize : 12
      })

    }

    return customStyles;
  };

  render() {

    const placeholder  = this.props.placeholder.toLowerCase();
  
    const srcArray     = (placeholder === "category")   ?   this.props.AppReducer.serverCategories : this.props.AppReducer.serverEmploymentTypes;
    const ele_id       = (placeholder === "category")   ?  "category-select-id" : "employment-select-id";
  // const button_id    = (placeholder === "category")   ? "category-button-id" : "employment-button-id";
  // const buttonText   = (placeholder === "category")   ? "category" : "term";
           
  //  let options      =    srcArray.map( (option) => {
  //                         const newOption = {value: option, label: option}
  //                         return newOption;        
  //  });

//    const options = ['Monday', 'Tuesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  
    return (
      
      <div>

      <select onChange     = {this.handleChange}
              id           = { ele_id }
              className    = "dl_option_filters"
              defaultValue = "default"
              >
              
              <option value="default" disabled> {  placeholder === "category" ? "View Jobs by Category" : "View Jobs by Type" }  </option>  
              {
                srcArray.map(item => {
                    return (<option key={item} value={item}>{item}</option>)
                })
              }
      </select>

      </div>


    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)( ReactComboFilter );
/*
<Autocomplete id= { ele_id}
options     = {options}
renderInput= { (params) =>
  <TextField
     {...params}
     InputLabelProps= {{style: {color:"black", fontSize: 14}}} // font size of input label
     label          = {  placeholder === "category" ? "View Jobs by Category" : "View Jobs by Type" }
  />}
/>
</div>
*/



/*
<div>
<Select placeholder  = { placeholder === "category" ? "View Jobs by Category" : "View Jobs by Type" } 
        isClearable  = { true }
        options      = { options }
        styles       = { customStyles}
        onChange     = { this.handleChange}
 />
</div>
*/