import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button         from '@material-ui/core/Button';
import { toast }      from 'react-toastify';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    textTransform: "none",
    fontFamily:'Open Sans',
    fontSize  : 12
  },
}));

export default function MailerButton( obj ) {

  const classes = useStyles();

  if  ( obj.store.selectedCount === 0 )
        return null;

 // if  ( obj.store.currentView === "SEARCH_VIEW" )
 //     return null;

   
 const handleButtonClick = event => {
   
  if ( obj.store.selectedCount === 0){
       toast.error( "No Candidates Selected" );
   } else {
       obj.actions.PWA_Post_Email_Toogle();
   }
};


  return (
    <div>
      <Button  onClick = { handleButtonClick } 
               variant="contained" 
               className={classes.button}>
        Send Email
      </Button>
    </div>
  );
}