import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button         from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  button: {
     margin: theme.spacing(1),
     textTransform: "none",
     fontFamily:'Open Sans',
     fontSize  : 12
  },
}));

export default function ResetButton( obj ) {

  const classes = useStyles();
 
  const handleButtonClick = event => {
    
     // V2 lets also reset the 2 combo and the autosuggext
      document.querySelector("#category-select-id").selectedIndex = 0;
      document.querySelector("#employment-select-id").selectedIndex = 0;
      obj.actions.PWA_Reset_Candidates();
      
  };

  return (
    <div>
      <Button   id="globalResetButton"
                onClick = { handleButtonClick } 
                variant="contained" 
                className={classes.button}>
        Reset Search
      </Button>
    </div>
  );
}