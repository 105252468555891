import React            from 'react';
import { makeStyles }   from '@material-ui/core/styles';
import AppBar           from '@material-ui/core/AppBar';
import Toolbar          from '@material-ui/core/Toolbar';
import Fab              from '@material-ui/core/Fab';
import Badge            from '@material-ui/core/Badge';
import MailIcon         from '@material-ui/icons/Mail';

import MailerButton       from './MailerButton';
import ResetButton        from './ResetButton';
import MailerModal        from './MailerModal';

const useStyles = makeStyles(theme => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundImage: "linear-gradient(-4deg, #b9812c 0%, #e3c74d 100%)",
    color: "#fff"
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
    backgroundImage: "linear-gradient(40deg, #b9812c 0%, #e3c74d 100%)",
    
  }
 }));

/*
function handleFabClick( obj ) {
  switch (obj.store.currentView) {
    case "SEARCH_VIEW":
      obj.actions.PWA_Set_View("MAILER_VIEW");
      return ;
    case "MAILER_VIEW":
      obj.actions.PWA_Set_View("SEARCH_VIEW");
      return;
    default:
      return;
  }
} 
*/

export default function BottomAppBar( obj ) {

   const classes                 = useStyles();
   const barColorScheme          = obj.store.currentView === "SEARCH_VIEW" ? "primary"   : "secondary"
   const fabColorScheme          = obj.store.currentView === "SEARCH_VIEW" ? "secondary" : "primary"
 //  const fabIcon                 = obj.store.currentView === "SEARCH_VIEW" ? (<AlternateEmailOutlinedIcon />) : (<SearchIcon />)
 //  const fabIcon                 = <AlternateEmailOutlinedIcon />;
   
   return (
      <React.Fragment>
      <AppBar id="bottomAppBar" position="fixed" color={ barColorScheme } className={classes.appBar}>
        <Toolbar>
      
         {/* not required in whats availibe    <Fab  onClick = { () => handleFabClick(obj) } color={ fabColorScheme } aria-label="add" className={classes.fabButton}>
            {fabIcon}
            </Fab>
         */}

          <ResetButton    store   = { obj.store      }
                          actions = { obj.actions    } />
      
          <Fab color={ fabColorScheme } aria-label="add" className={classes.fabButton}>
          <Badge color="primary" badgeContent={ obj.store.selectedCount}>
             <MailIcon />
          </Badge>
            {/* {fabIcon} */}
          </Fab>

          <div className={classes.grow} />

          <MailerButton     store   = { obj.store      }
                            actions = { obj.actions    } />
       
          <MailerModal      store   = { obj.store }
                            actions = { obj.actions    } /> 

          {/* not required in whats availibe
           <AdvancedButton     store   = { obj.store      }
                              actions = { obj.actions    } />
          */}
  
        </Toolbar>
      </AppBar>
    </React.Fragment>
    );
  }
