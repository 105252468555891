import React, { Component }        from 'react';
import { connect }                 from 'react-redux';
import { bindActionCreators }      from 'redux';
import * as AppActions             from './store/actions/AppActions';
import CssBaseline                 from '@material-ui/core/CssBaseline';
import BottomAppBar                from './components/BottomAppBar'
import SearchHeaderAppBar          from './components/SearchHeaderAppBar'
import MailerHeaderAppBar          from './components/MailerHeaderAppBar'
import CandidatesList              from './components/CandidatesList'
import DetailModal                 from './components/DetailModal';
import AdvancedSearch              from './components/AdvancedSearch';
import Instructions                from './components/Instructions';
import PermanentDetailsModal       from './components/PermanentDetailsModal';

import { ToastContainer }          from 'react-toastify';


import 'react-toastify/dist/ReactToastify.min.css';
import './App.css';

function mapDispatchToProps(dispatch) {
   const actions = Object.assign( {}, AppActions );
   return { actions: bindActionCreators( actions, dispatch)  };
}

function mapStateToProps(store) {
   return { AppReducer  : store.AppReducer };
}


class App extends Component {

  componentDidMount() {
     
       this.props.actions.PWA_Get_Jobs();
  }

  render() {
    
    return (
      <React.Fragment>
           <CssBaseline />
       
           <SearchHeaderAppBar  store   = { this.props.AppReducer }
                                actions = { this.props.actions    }/>

           <MailerHeaderAppBar  store   = { this.props.AppReducer } 
                                actions = { this.props.actions    }/>
       
           <CandidatesList      store   = { this.props.AppReducer }
                                actions = { this.props.actions    }/>
       
  
           <BottomAppBar       store   = { this.props.AppReducer }
                               actions = { this.props.actions    }/>

           <ToastContainer      position="top-center"
                                autoClose={2000}
                                hideProgressBar={false}
                                newestOnTop
                                closeOnClick={false}
                                rtl={false}
                                pauseOnVisibilityChange
                                draggable={false}
                                pauseOnHover={false}/>
           
            <DetailModal     store   = { this.props.AppReducer }
                             actions = { this.props.actions    } /> 

            <PermanentDetailsModal    store   = { this.props.AppReducer }
                                      actions = { this.props.actions    } /> 

            <AdvancedSearch  store   = { this.props.AppReducer }
                             actions = { this.props.actions    } /> 

            <Instructions    store   = { this.props.AppReducer }
                             actions = { this.props.actions    } /> 
    
   </React.Fragment>
       
  );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)( App );