import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
   // width: '80vw'
  },
}));

export default function MailerInput( { _mailerValues, _mailerChange} ) {
  
  const classes = useStyles();
  return (
    <div className={classes.root}>
     
     <TextField
        autoFocus
        required
        id="outlined-adornment-name"
        className={clsx(classes.margin, classes.textField)}
        variant="outlined"
        label="Name"
        value    = {_mailerValues.name}
        onChange = {_mailerChange('name')}
      />
     
      <TextField
        required
        id="outlined-simple-email"
        className={clsx(classes.margin, classes.textField)}
        variant="outlined"
        label="Email"
        value    = {_mailerValues.email}
        onChange = {_mailerChange('email')}
      />
 
      <TextField
        required
        id="outlined-adornment-contact"
        className={clsx(classes.margin, classes.textField)}
        variant  ="outlined"
        label    ="Contact No"
        value    = {_mailerValues.phone}
        onChange = {_mailerChange('phone')}
      />
  
      <TextField
        id        ="outlined-adornment-comment"
        className={clsx(classes.margin, classes.textField)}
        variant  ="outlined"
        label    ="Comment"
        value    = {_mailerValues.comment}
        onChange = {_mailerChange('comment')}
      />
  
    </div>
  );
}
