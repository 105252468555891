import React, { Component } from 'react'
import { connect }                  from 'react-redux';
import { bindActionCreators }       from 'redux';
import * as AppActions              from '../store/actions/AppActions';
import Autosuggest                  from 'react-autosuggest';

function mapDispatchToProps(dispatch) {
    const actions = Object.assign( {}, AppActions );
    return { actions: bindActionCreators( actions, dispatch)  };
}

function mapStateToProps(store) {
   return { AppReducer : store.AppReducer };
}

class ReactAutoSuggest extends Component {

  constructor(props) {
      super(props);
      this.getSuggestionValue            =  this.getSuggestionValue.bind(this);
      this.state                         =  { value : '', suggestions : [] };
  }

  escapeRegexCharacters = (str) => {
       return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
  
  onChange = (event, { newValue }) => {
    if (newValue === undefined )
       this.setState({value: ""});
    else
       this.setState({value: newValue});
  };

  shouldRenderSuggestions = (value) => {
      return value.trim().length >= 1;
  }

  renderSuggestion = (suggestion) => {
    return (
      <span>{suggestion.name}</span>
    );
  }
  
  onSuggestionsFetchRequested = (object) => {

    console.log( object);
   
    if ( object.reason !== "input-changed")
         return [];
         
    const escapedFilter  = this.escapeRegexCharacters( object.value );
   
    if (escapedFilter === '') { return [] }
 
    const regex            = new RegExp('^' + escapedFilter, 'i');

    const placeHolder      = this.props.placeholder.toLowerCase();

    var srcArray           = [];
    
    switch(placeHolder) {
      case 'location':
           srcArray = this.props.AppReducer.serverLocations;
           break;
      case 'category':
           srcArray = this.props.AppReducer.serverCategories;
           break;
      case 'employment':
           srcArray = this.props.AppReducer.serverEmploymentTypes;
           break;
      default:
           srcArray = [];
    }
    
    let  suggestions = [];
    
     srcArray.filter( item => { 
          if ( regex.test( item.toLowerCase() ) ) {
               suggestions.push( { name: item} );
          };
          return true;
    });
    
    this.setState({suggestions: suggestions});

  };
  
  onSuggestionsClearRequested = () => {
     this.setState({ suggestions: []});
  };
  
  getSuggestionValue( suggestion) {
    
    let filterField = this.props.placeholder.toLowerCase();
    let filterValue = suggestion.name;
    this.props.actions.PWA_Get_Candidates(filterField, filterValue);
    return suggestion.name;
  }
    
  render() {

  
    const { value, suggestions } = this.state;
    const inputProps = {
      id           : "location-filter-id",
      type         : "search",
      placeholder  : `[Search by Location]`, //`[${this.props.placeholder}]`, /*v2.2 changed
      value,   
      onChange     : this.onChange
    };

    return (

      <Autosuggest

        onSuggestionsFetchRequested = { this.onSuggestionsFetchRequested }
        onSuggestionsClearRequested = { this.onSuggestionsClearRequested }
        getSuggestionValue          = { this.getSuggestionValue }
        shouldRenderSuggestions     = { this.shouldRenderSuggestions } 
        renderSuggestion            = { this.renderSuggestion }
        suggestions                 = { suggestions }
        inputProps                  = { inputProps  }
        focusInputOnSuggestionClick = { false}
        
      />
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)( ReactAutoSuggest );