import React            from 'react';
import { withStyles }   from '@material-ui/core/styles';

import Button           from '@material-ui/core/Button';
import Dialog           from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

//import Typography       from '@material-ui/core/Typography';

import Paper            from '@material-ui/core/Paper';
import Slide            from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
}))(MuiDialogContent);


const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


export default function PermanentDetailsModal( obj ) {

  if  ( obj.store.showPermanentDetails.show === false )
        return null;

  //const [values, setValues] = React.useState({ details :  ''  });
  
  const handleToggle  = () => { obj.actions.PWA_Show_Permanent_Details_Close() }

  return (
    <div>

      <Dialog
              fullWidth           = {true}
              TransitionComponent = {Transition}
              keepMounted
              onClose             = { () => obj.actions.PWA_Show_Permanent_Details_Close() }
              aria-labelledby     = "customized-dialog-title"
              open                = { obj.store.showPermanentDetails.show }
             >


        <DialogContent dividers>
          <Paper className="details_description_text" elevation={0}> 
                      { obj.store.showPermanentDetails.details }
          </Paper>
        </DialogContent>


        <DialogActions>
             <Button onClick=   { handleToggle }    color="primary">Close</Button>
        </DialogActions>

      </Dialog>

    </div>
  );
}